import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Set the base URL to your backend server
// axios.defaults.baseURL = 'https://byyb.live';
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const TopMenu = ({ toggleSidebar, onSetSelect }) => {
  const [tickerText, setTickerText] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isFetchingRandomSet, setIsFetchingRandomSet] = useState(false);

  useEffect(() => {
    // Fetch the ticker text from the backend API
    const fetchTickerText = async () => {
      try {
        const response = await axios.get('/api/next_week_programs/');
        setTickerText(response.data.tickerText);
      } catch (err) {
        console.error('Error fetching ticker text:', err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchTickerText();
  }, []);

  // Function to handle "Surprise Me" button click
  const handleSurpriseMe = async () => {
    setIsFetchingRandomSet(true);
    try {
      const response = await axios.get('/api/random_dj_set/');
      const randomSet = response.data.set;

      if (randomSet) {
        // Increment listen count before playing the set
        try {
          const incrementResponse = await axios.post(
            `/api/performances/${randomSet.id}/increment_listen_count/`
          );
          console.log('Listen count incremented:', incrementResponse.data.listen_count);

          // Update the listen_count in randomSet object
          randomSet.listen_count = incrementResponse.data.listen_count;

          // Now play the random DJ set
          onSetSelect(randomSet);
        } catch (err) {
          console.error('Error incrementing listen count:', err);

          // Proceed to play the set even if increment fails
          onSetSelect(randomSet);
        }
      }
    } catch (err) {
      console.error('Error fetching random DJ set:', err);
    } finally {
      setIsFetchingRandomSet(false);
    }
  };

  return (
    <div className="top-menu">
      {/* Wrap the logo in an anchor tag */}
      <div className="logo">
        <a href="/">
          <img src="/static/logo/byyb.png" alt="Logo" />
        </a>
      </div>

      <div className="ticker">
        {loading && <div className="ticker-loading">Loading programs...</div>}
        {error && <div className="ticker-error">Error loading programs.</div>}
        {!loading && !error && (
          <div className="ticker-text">{tickerText}</div>
        )}
      </div>

      {/* Surprise Me Button */}
      <button
        className="surprise-me-btn"
        onClick={handleSurpriseMe}
        disabled={isFetchingRandomSet}
        aria-label="Play a random DJ set"
      >
        {isFetchingRandomSet ? 'Loading...' : 'Surprise Me!'}
      </button>

      <button className="sidebar-toggle-btn" onClick={toggleSidebar}>
        ☰
      </button>
    </div>
  );
};

export default TopMenu;
